import { ChangeDetectorRef, Component, OnDestroy, Output, EventEmitter, Input } from '@angular/core';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { MediaMatcher } from '@angular/cdk/layout';
import { MenuItems } from '../../../shared/menu-items/menu-items';
import { TranslateService } from '@ngx-translate/core';
import { Title } from '@angular/platform-browser';

@Component({
    selector: 'app-vertical-sidebar',
    templateUrl: './vertical-sidebar.component.html',
    styleUrls: [],
})
export class VerticalAppSidebarComponent implements OnDestroy {
    private _mobileQueryListener: () => void;

    public config: PerfectScrollbarConfigInterface = {};
    public mobileQuery: MediaQueryList;

    public status = true;

    public itemSelect: number[] = [];
    public parentIndex = 0;
    public childIndex = 0;

    @Input() showClass: boolean = false;
    @Output() notify: EventEmitter<boolean> = new EventEmitter<boolean>();

    constructor(
        changeDetectorRef: ChangeDetectorRef,
        media: MediaMatcher,
        public menuItems: MenuItems,
        private title: Title,
        private translate: TranslateService
    ) {
        this.mobileQuery = media.matchMedia('(min-width: 768px)');
        this._mobileQueryListener = () => changeDetectorRef.detectChanges();
        // tslint:disable-next-line: deprecation
        this.mobileQuery.addListener(this._mobileQueryListener);
    }

    setClickedRow(i: number, j: number): void {
        this.parentIndex = i;
        this.childIndex = j;
    }
    subclickEvent(): void {
        this.status = true;
    }
    scrollToTop(): void {
        document.querySelector('.page-wrapper')?.scroll({
            top: 0,
            left: 0,
        });
    }

    getLink(menuState: any, menuChildItem: any = undefined, menuChildState: any = undefined) {
        let result: any[] = ['/'];

        if (Array.isArray(menuState)) result = [...result, ...menuState];
        else result.push(menuState);

        if (menuChildItem)
            if (Array.isArray(menuChildItem)) result = [...result, ...menuChildItem];
            else result.push(menuChildItem);

        if (menuChildState)
            if (Array.isArray(menuChildState)) result = [...result, ...menuChildState];
            else result.push(menuChildState);

        return result;
    }

    ngOnDestroy(): void {
        // tslint:disable-next-line: deprecation
        this.mobileQuery.removeListener(this._mobileQueryListener);
    }

    handleNotify(data: string) {
        this.title.setTitle(this.translate.instant(data));
        if (window.innerWidth < 1024) {
            this.notify.emit(!this.showClass);
        }
    }
}
