import { Component, OnInit, ViewChild, AfterViewInit, OnDestroy, ElementRef, Inject } from '@angular/core';
import { MatTableDataSource, MatTable } from '@angular/material/table';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DatePipe } from '@angular/common';
import { Subscription } from 'rxjs';
import Swal from 'sweetalert2';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from 'src/app/providers/language.service';
import { Router } from '@angular/router';
import { AccountService } from 'src/app/providers/account.service';
import { KeycloakService } from 'keycloak-angular';

@Component({
    templateUrl: './auth.component.html',
    styleUrls: ['./auth.component.scss'],
})
export class AppAuthNavigatorComponent implements OnInit, AfterViewInit, OnDestroy {
    // Translate
    private language: string = 'vn';
    private languageSub!: Subscription;

    @ViewChild('iframeAuthElement') iframeAuthElement: ElementRef | undefined;

    constructor(
        private translate: TranslateService,
        private languageService: LanguageService,
        private router: Router,
        private accountService: AccountService,
        private keycloakService: KeycloakService,
    ) {}

    /** INIT */
    ngOnInit(): void {
        // Translate
        this.language = this.languageService.getLanguage();
        this.translate.use(this.language);
        this.languageSub = this.languageService.getLanguageUpdateListener().subscribe((newLanguage) => {
            this.language = newLanguage;
            this.translate.use(this.language);
        });
        this.keycloakService.register()
    }

    ngOnDestroy(): void {
        if (this.languageSub) this.languageSub.unsubscribe();
        if (this.iframeAuthElement)
            this.iframeAuthElement.nativeElement.contentWindow.removeEventListener('message', this.onReceiveEvent);
    }

    async ngAfterViewInit() {
        if (this.iframeAuthElement) {
            this.iframeAuthElement.nativeElement.contentWindow.addEventListener('message', this.onReceiveEvent);
        }
    }

    /** UI FUNCTIONS */

    /** OTHER EVENTS */
    onReceiveEvent(event: any) {
        console.log('[APP AUTH] receive message ', event.data, event);
    }

    /** OTHER FUNCTIONS */
}
