import { Injectable } from '@angular/core';
import { Routes } from '@angular/router';
import { AccountService } from 'src/app/providers/account.service';
export interface BadgeItem {
    type: string;
    value: string;
}
export interface Saperator {
    name: string;
    type?: string;
}
export interface SubChildren {
    state: string | string[];
    name: string;
    type?: string;
}
export interface ChildrenItems {
    state: string | string[];
    name: string;
    type?: string;
    child?: SubChildren[];
}

export interface Menu {
    state: string | string[];
    name: string;
    type: string;
    icon: string;
    badge?: BadgeItem[];
    saperator?: Saperator[];
    children?: ChildrenItems[];
}

export const KHAO_SAT = {
    state: ['khao-sat', 'danh-sach-khao-sat'],
    name: 'danh_sach_phieu_khao_sat',
    type: 'link',
    icon: 'library_books',
};

export const CHI_TIET_KHAO_SAT = {
    state: ['khao-sat', 'chi-tiet-khao-sat'],
    name: 'khao_sat_muc_do_hai_long',
    type: 'link',
    icon: 'mood',
};

// export const KHAO_SAT_MUC_DO_HAI_LONG = {
//     state: ['khao-sat', 'khao_sat_muc_do_hai_long'],
//     name: 'khao_sat_muc_do_hai_long',
//     type: 'link',
//     icon: 'mood',
// };

// export const KHAO_SAT_MUC_DO_HAI_LONG_2023 = {
//     state: ['khao-sat', 'khao_sat_muc_do_hai_long_2023'],
//     name: 'khao_sat_muc_do_hai_long_2023',
//     type: 'link',
//     icon: 'mood',
// };

export const TAI_KHOAN = {
    state: ['account', 'editaccount'],
    name: 'Accounts',
    type: 'link',
    icon: 'account_circle',
};

export const MENUITEMS = [
    KHAO_SAT,
    CHI_TIET_KHAO_SAT,
    // KHAO_SAT_MUC_DO_HAI_LONG,
    // KHAO_SAT_MUC_DO_HAI_LONG_2023,
    TAI_KHOAN,
];

@Injectable({ providedIn: 'root' })
export class MenuItems {
    constructor(private accountService: AccountService) {}

    getMenuitem(): Menu[] {
        return MENUITEMS;
    }
}

export function updateMenuItemsRole() {}
