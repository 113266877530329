import { Injectable } from '@angular/core';

export const KHAO_SAT = 'https://ks.vnuhcm.edu.vn';

@Injectable({ providedIn: 'root' })
export class HostnameService {
    get KhaoSatAPI() {
        return KHAO_SAT;
    }

    get KhaoSatAPI_Producer() {
        return 'https://ks.vnuhcm.edu.vn/api/v1';
        // return 'http://localhost:5000';
    }
}
