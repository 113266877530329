<div class="login-register">
    <div class="login-register-box error-card text-center">
        <h1>404</h1>
        <h3 class="text-uppercase">{{ 'Page Not Found' | translate }} !</h3>
        <a
            mat-raised-button
            color="primary"
            [routerLink]="['/']"
            class="btn btn-info btn-rounded waves-effect waves-light m-b-40"
        >
            {{ 'Back to home' | translate }}
        </a>
    </div>
</div>
