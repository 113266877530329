import { Component, OnDestroy } from '@angular/core';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from 'src/app/providers/language.service';
import { AccountService } from 'src/app/providers/account.service';
import { KeycloakService } from 'keycloak-angular';
import { Router } from '@angular/router';
import { AccountAvatarService } from 'src/app/providers/avatar.service';
import { Subscription } from 'rxjs';
import { commonRequestHandle } from 'src/app/utils/stop-await';

@Component({
    selector: 'app-vertical-header',
    templateUrl: './vertical-header.component.html',
    styleUrls: [],
})
export class VerticalAppHeaderComponent implements OnDestroy {
    public config: PerfectScrollbarConfigInterface = {};

    public selectedLanguage: any = {
        language: 'Tiếng Việt',
        code: 'vn',
        type: 'VN',
        icon: 'vn',
    };

    public languages: any[] = [
        {
            language: 'English',
            code: 'en',
            type: 'US',
            icon: 'us',
        },
        {
            language: 'Tiếng Việt',
            code: 'vn',
            type: 'VN',
            icon: 'vn',
        },
    ];

    avatarLink: string = '';
    avatarLinkSub: Subscription;

    constructor(
        private translate: TranslateService,
        private languageService: LanguageService,
        private keycloakService: KeycloakService,
        private router: Router,
        private accountAvatarService: AccountAvatarService,
        private accountService: AccountService
    ) {
        translate.setDefaultLang('vn');

        if (!this.accountAvatarService.AvatarLink) this.avatarLink = '/assets/images/users/default.png';
        else this.avatarLink = this.accountAvatarService.AvatarLink;

        this.avatarLinkSub = this.accountAvatarService.AvatarLinkSub.subscribe((value: string) => {
            if (!value) this.avatarLink = '/assets/images/users/default.png';
            else this.avatarLink = value;
        });
    }

    async ngAfterViewInit() {
        await this.accountAvatarService.updateAvatarLink();
    }

    ngOnDestroy() {
        if (this.avatarLinkSub) this.avatarLinkSub.unsubscribe();
    }

    changeLanguage(lang: any): void {
        this.translate.use(lang.code);
        this.selectedLanguage = lang;
        this.languageService.setLanguage(lang.code);
    }

    isShowUpdateAttrs() {
        let roles = this.accountService.getRoles();
        return roles.includes('update_account_reference');
    }

    logout() {
        this.keycloakService.logout();
    }

    accountInfo() {
        this.router.navigateByUrl('/account/editaccount');
    }

    async updateAccountAttrs() {
        let result = await commonRequestHandle(this.translate, () => this.accountService.updateAccountAttrs(), {
            show_loading: true,
            close_before_show_loading: true,
            close_after_show_loading: true,
            show_success: true,
            create_success_message: (result) => 'Data: ' + JSON.stringify(result.data),
            timeout_auto_close_success: 5000,
        });
        if (!result) return;
    }
}
