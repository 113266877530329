import { Routes } from '@angular/router';

import { FullComponent } from './layouts/full/full.component';
import { AppBlankComponent } from './layouts/blank/blank.component';
import { AuthGuard } from './helper/auth.guard';
import { ErrorComponent } from './error-pages/error.component';
import { AppAuthNavigatorComponent } from './auth/auth.component';

export const AppRoutes: Routes = [
    {
        path: 'auth',
        component: AppAuthNavigatorComponent,
    },
    {
        path: '',
        component: FullComponent,
        canActivate: [AuthGuard],
        data: { everyRoles: ['vnuhcm.vudp.khaosatsuhailong'] },
        children: [
            {
                path: '',
                redirectTo: '/khao-sat/chi-tiet-khao-sat',
                pathMatch: 'full',
            },
            {
                path: 'khao-sat',
                loadChildren: () => import('./khao_sat/khao_sat.module').then((m) => m.KhaoSatModule),
            },
            {
                path: 'account',
                loadChildren: () => import('./account/account.module').then((m) => m.AccountModule),
            },
        ],
    },
    {
        path: '**',
        pathMatch: 'full',
        component: ErrorComponent,
    },
    // {
    //     path: '**',
    //     redirectTo: 'authentication/404',
    // },
];
