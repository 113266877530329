import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';
import Swal from 'sweetalert2';
import { HostnameService } from './hostname.service';

@Injectable({ providedIn: 'root' })
export class AccountService {
    constructor(
        private httpClient: HttpClient,
        private hostnameService: HostnameService,
        private keyCloakService: KeycloakService
    ) {}

    getUserRoles(webapp: string = 'webapp-ks'): string[] {
        let keyCloakInstance: any = this.keyCloakService.getKeycloakInstance();
        let parseToken = keyCloakInstance?.tokenParsed;
        let userRoles: string[] = [];
        if (parseToken?.resource_access[webapp]) {
            userRoles = parseToken?.resource_access[webapp].roles;
        }
        return userRoles;
    }

    updateUserdata() {
        let keyCloakInstance: any = this.keyCloakService.getKeycloakInstance();
        let parseToken = keyCloakInstance?.tokenParsed;
        let clientId = keyCloakInstance.clientId;
        let userRole = [];

        if (parseToken?.resource_access['webapp-ks']) {
            userRole = parseToken?.resource_access['webapp-ks'].roles;
        }
    }

    async updateUserProfile() {
        await this.keyCloakService.loadUserProfile(true);
    }

    getSubject() {
        return this.keyCloakService.getKeycloakInstance().subject;
    }

    getProfile() {
        return this.keyCloakService.getKeycloakInstance().profile;
    }

    getRoles(): string[] {
        return this.keyCloakService.getUserRoles();
    }

    getToken(): string | undefined {
        return this.keyCloakService.getKeycloakInstance().token;
    }

    getAPIKey() {
        return this.getInfo().apikey || 'default_key';
    }

    getInfo() {
        let profile: any = this.getProfile();
        let keys = [
            'ho_va_ten',
            'mssv',
            'nam_sinh',
            'do_tuoi',
            'ma_truong',
            'truong',
            'khoa_tuyen_sinh',
            'khoa',
            'nganh',
            'chuong_trinh',
            'apikey',
        ];
        let result: any = {};
        if (profile.attributes)
            keys.forEach((key: string) => {
                if (Array.isArray(profile.attributes[key]) && profile.attributes[key].length > 0)
                    result[key] = profile.attributes[key][0];
            });
        return result;
    }

    changeUserInfo(info: any, attrs: any = undefined) {
        return this.httpClient
            .post(
                this.hostnameService.KhaoSatAPI_Producer + '/account-info/change-info',
                {
                    email: info.email,
                    attrs: attrs,
                },
                {
                    headers: { apikey: this.getAPIKey() },
                }
            )
            .toPromise();
    }

    requestChangePassword() {
        return this.httpClient
            .get(this.hostnameService.KhaoSatAPI_Producer + '/account-info/request-reset-password', {
                headers: { apikey: this.getAPIKey() },
            })
            .toPromise();
    }

    updateAccountAttrs() {
        return this.httpClient
            .get(this.hostnameService.KhaoSatAPI_Producer + '/account-info/update-account-attrs', {
                headers: { apikey: this.getAPIKey() },
            })
            .toPromise();
    }
}
