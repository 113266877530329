import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';
import { Subject } from 'rxjs';
import Swal from 'sweetalert2';
import { AccountService } from './account.service';
import { HostnameService } from './hostname.service';

@Injectable({ providedIn: 'root' })
export class AccountAvatarService {
    private _avatarLink: string = '';
    private _avatarLinkSub: Subject<string> = new Subject<string>();

    public get AvatarLink() {
        return this._avatarLink;
    }

    public set AvatarLink(value: string) {
        this._avatarLink = value;
        this._avatarLinkSub.next(value);
    }

    public get AvatarLinkSub() {
        return this._avatarLinkSub;
    }

    constructor(
        private httpClient: HttpClient,
        private hostnameService: HostnameService,
        protected readonly keycloak: KeycloakService,
        private accountService: AccountService
    ) {}

    updateUserAvatar(file: Blob) {
        let formData = new FormData();
        formData.append('file', file);

        return this.httpClient
            .post<any>(this.hostnameService.KhaoSatAPI_Producer + '/account-avatar/change', formData, {
                headers: {
                    apikey: this.accountService.getAPIKey(),
                },
            })
            .toPromise();
    }

    async updateAvatarLink() {
        let result: any = await this.httpClient
            .get<any>(this.hostnameService.KhaoSatAPI_Producer + '/account-avatar/get-presigned', {
                headers: {
                    apikey: this.accountService.getAPIKey(),
                },
            })
            .toPromise();
        if (result.status == 'error') {
            this.AvatarLink = '';
            return;
        }
        this.AvatarLink = result.link;
    }
}
